import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Navigation from '../components/navigation'
import Transition from '../components/transition'

const Layout = ({ pageTitle, children, location, pageTitleVisible=true, pageWidth="normal"}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
        <Navigation></Navigation>
        <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}>
        <html lang="en" />
            </Helmet>
          <main>
        <Transition location={location}>
          {children}
        </Transition>
        </main>
            </>
  )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
  }
  
export default Layout